import React, {useState} from 'react'
import less from '../../images/minus.svg'
import more from '../../images/add.svg'

const Faq = ({title, desc}) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="co-res-item co-paper">
            <button type="button" className="accordion" onClick={()=>setOpen(!open)}>{title}  <img src={!open? more: less} alt="collapse" /></button>
            {
                open &&  <div className="accordion-panel">
                    {
                        desc.map((item, index)=>{
                            return <p key={index}>
                                {item}
                            </p>
                        })
                    }
                </div>
            }
        </div>
    )
}

export default Faq