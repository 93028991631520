import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/sections/Contact"
import Faq from '../ui/faq/Faq'

const Faqpage = () => {

    const faqsList = [
        {
            title: "What are integration options?",
            text: [
                "For PCI DSS compliant Merchants there is a Digimeth H2H solution. It is a host-to-host API page that is hosted on the Merchant's end.",
                "For Merchants without PCI DSS there is Digimeth Payment Form — PSP payment form hosted on Digimeth's side. But a Merchant can easily transform it according to any requirements."
            ]
        },
         {
            title: "What is PCI-DSS?",
            text: [
                "PCI-DSS is a payment card industry data security standard developed by the Payment Card Industry Security Standards Council (PCI SSC), founded by the international payment systems Visa, MasterCard, American Express, JCB, and Discover. The standard is a set of 12 detailed security requirements for payment cardholder data that is transmitted, stored, and processed within organizations' information infrastructures. Adopting appropriate compliance measures implies a comprehensive approach to payment card data information security."
            ]
        },
        {
            title: "Which payment methods are available?",
            text: [
                "We provide various payment methods. The range depends on the region. But to give you a quick preview, please find a few available for all regions: VISA; MasterCard; PayPal; Google Pay; Apple Pay.", "To get more information, please contact us"
            ]
        },
        {
            title: "How can I get more information?",
            text: [
                "Our support team is ready to assist, address, clarify and answer all inquiries and questions you may have."
            ]
        },
        {
            title: "Why did my payment get declined?",
            text: [
                "You should make sure that all card details are entered correctly, the card is active and topped up. Payment can be declined for several reasons: Insufficient funds; Invalid card details; Your card has expired; Your billing address does not match the address on your card statement; CVV/CVV2 number is incorrect or incomplete."
            ]
        },
        {
            title: "How can I make my payment be returned?",
            text: [
                "If the payment was made mistakenly, you're not satisfied with the product/service, or you didn't make the payment yourself, you should contact the customer support of the website where you made the payment and request a refund. You can be asked to enclose your purchase receipt or provide other requested information and state the reason for the refund. Each site has its own refund policy, so the refund amount may vary."
            ]
        },
        {
            title: "What is a chargeback (dispute)?",
            text: [
                "A chargeback (dispute) is a procedure for disputing a transaction initiated by the issuer (to protect the customer's rights) following the withdrawal of the payment amount from the merchant (acquirer) and its refund to the cardholder. A chargeback is initiated when the customer requests the issuing bank to cancel the payment. You can prevent many of these chargebacks. The more you know about the proper procedures, the less likely you will run into problems that could result in a chargeback."
            ]
        },
        {
            title: "What are chargeback rates and limits?",
            text: [
                "The rate allowed often depends on various factors, such as card scheme, chargeback history, and employment type.  - VISA Chargeback rate of 0.9% and 100 chargebacks. - Mastercard 1.5% chargeback rate and 100 chargebacks.  -Note: If your chargeback rate is 10%, but you have less than 100 chargebacks, or your chargeback rate is 0.05%, and you have more than 100 chargebacks — you have not breached your limit."
            ]
        },
        {
            title: "Can we dispute a refund?",
            text: [
                "Yes, you can challenge disputes if you have proof that the transaction is valid or the transaction amount is higher than the original chargeback. However, if you dispute the refund, it will go through the entire thread."
            ]
        },
        {
            title: "When merchant should accept chargeback?",
            text: [
                "A merchant should accept chargeback if: The customer is correct. You accept liability. The transaction should have been refunded. Challenging the chargeback is not worth the consequent expense."
            ]
        },
        {
            title: "What is fraud?",
            text: [
                "Fraud is a card scam intended to unlawfully use money from your card account. There are a few types: Friendly fraud — actual cardholders make purchases and then demand a refund from the bank; Phishing — scams that target sensitive data (often mailings with fake websites); Skimming — theft of card data information using a skimmer — a device made in the form of an overlay on an ATM card reader."
            ]
        },
        {
            title: "How to detect a potential fraud?",
            text: [
                "There are several ways to identify fraudulent transactions that can negatively affect a merchant and a bank. Timely detected fraud activity can save a merchant's business from financial losses. Customer is using a proxy; Customer tries to make a purchase with several credit cards in a short period of time; Many failed transactions in a short period of time; Customer's email address or domain is suspicious; Customer's IP address and credit card address mismatch; Customer tries to purchase an unusually large order; Customer uses fake information (fake name, phone number, email etc.); Customer's transaction IP is from the risky country; Shipping address is from the risky country;"
            ]
        },

        {
            title: "What is 3-D Secure?",
            text: [
                "3-D Secure is an XML-based protocol designed to prevent fraudulent use of cards. It is an additional verification step required to make an online payment. When using 3-D Secure, the cardholder will be required to enter additional verification, such as a code generated by a key generator or sent to the cardholder's phone and a password associated with the card. In other words, it is an online OTP (one-time password) that only the cardholder can know or have access to. Thus, the cardholder's funds will be protected if the card data is compromised or the card is stolen.",
            ]
        },

        {
            title: "What does protection 3DS offer? ",
            text: [
                "Merchants are not responsible for fraudulent purchases carried out involving 3-D Secure. As long as you manage to authenticate your customer using SCA or attempt to authenticate, but the card is not enrolled in the program, liability for fraudulent activity shifts to a card issuer.",
            ]
        },
        {
            title: "What are Alternative Payment Methods (APM)?",
            text: [
                "Alternative Payment Methods (APM) — alternative payment methods. Example: PayPal, Sofort, Ideal, USSD, and others.  ACH is an alternative payment method in the USA — online transfers. PSP — Payment Service Provider — a provider that accepts payments. SEPA — Single Euro Payments Area, bank transfers in EUR within EEA. ",
            ]
        },

        {
            title: "What types of transactions exist?",
            text: [
                "Types of transactions: SALE (Approved) — funds withdrawal; REFUND — a transaction of funds return; AUTH — funds freezing on the buyer's card; SETTLE (Capture) — Authorization capture (settle) takes place after a payment authorization. It’s when the authorized money is transferred from the customer’s account to a merchant’s account. VOID — cancel the authorization, unfreezing funds back to a customer;DECLINE — transaction received a decline for some reason.",
            ]
        },

        {
            title: "What types of cards exist?",
            text: [
                "Debit cards; Credit cards; Prepaid cards — can be anonymous and not anonymous.",
            ]
        },



        {
            title: "What is CVV verification?",
            text: [
                "CVV verification is a verification that the 3-character code entered by the client corresponds to its actual value. It is used as one of the anti-fraud tools.",
            ]
        },

        {
            title: "What is AVS verification?",
            text: [
                "AVS verification is the verification of information about the client's residence address against the data of the issuing bank. It is used as one of the tools to prevent card fraud.",
            ]
        },
        {
            title: "What is a Liability shift?",
            text: [
                "The BIN is the first six digits of the card by which you can identify the card's payment system, bank, country, card brand and other characteristics. Extremely important information.",
            ]
        }
        ,
        {
            title: "What is frictionless flow for charges with 3DS2?",
            text: [
                "As long as 3D Secure 2 allows businesses and their payment providers to send more data elements on each transaction to the cardholder's bank, it is provided that  the 3D Secure authentication process may happen without redirecting a user from the checkout page to 3DS form since the transaction is deemed eligible by cardholder's bank."
            ]
        }
    ]

  return (
        <Layout>
            <SEO title={`Faq - Digimeth.net`} />
            <article className='co-page'>
                <section className="co-side faq-section">
                    <div className="section-container">
                        <h2 className="section-title">
                            Frequently Asked Questions
                        </h2>
                        <div className="co-faq--list">
                            {
                                faqsList.map((item, index)=>{
                                    return <Faq title={item.title} key={index} desc={item.text}/>
                                })
                            }
                        </div>
                    </div>
                </section>
                <Contact />
            </article>
        </Layout>
    )
}

export default Faqpage